<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>人才引进</h2>
          <div class="tit">我们期待您的加入，一起创造未来</div>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>人才引进</h4>
          <div class="tit">我们期待您的加入，一起创造未来</div>
        </div>
      </div>

      <div class="customeValue">
        <h4 class="pro-tit">公司福利</h4>

        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/a.png" alt="" />
                <h6>薪酬收入</h6>
                <p>提供有竞争力的薪酬收入</p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/b.png" alt="" />
                <h6>内部晋升</h6>
                <p>员工培养，内部晋升</p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/c.png" alt="" />
                <h6>五险一金</h6>
                <p>提供五险一金缴纳</p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/d.png" alt="" />
                <h6>各项补贴</h6>
                <p>多种补贴报销等</p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/e.png" alt="" />
                <h6>节庆礼品</h6>
                <p>提供节庆日礼品等</p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/f.png" alt="" />
                <h6>带薪休假</h6>
                <p>法定节假日、带薪年假等</p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/dinner.png" alt="" />
                <h6>员工餐</h6>
                <p>提供午餐、加班晚餐等</p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/talent/h.png" alt="" />
                <h6>学习提升</h6>
                <p>岗位培训与行业知识培训</p>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
    </div>

    <NewFooter class="d-none d-lg-block" :show="false" :join="true"/>
    <NewFooter class="d-block d-lg-none" :show="false" :join="true"/>
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
          
    <script>
import { Newslist } from "@/api/fetch";
import Qs from "qs";
export default {
  name: "hardware",
  data() {
    return {
      cur: 1,
      activeName: "1",
    };
  },
  created() {},
  methods: {},
};
</script>
          
          <style lang="less"  scoped>
.apipe-cloud-course-container {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/talent/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p5.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .customeValue {
    margin: 50px 0;
    .pro-tit { 
      margin-bottom: 30px; 
    }
    .col {
      .p-3 {
        // border: 1px solid red;
        // min-height: 200px;
        // box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        box-shadow:0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
    p {
      color: #888;
      font-size: 13px;
    }
    h6 {
      margin-top: 1rem;
    }
  }
}
</style>
          